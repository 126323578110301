<template>
	<div v-if="detail">
		<img class="detail-img-bg" :src="detail.banner_imgs" alt="">
		<div class="chain-router">
			<router-link to="/">首页</router-link>
			<em>></em>
			<router-link to="/productCore">产品中心</router-link>
			<em>></em>
			<router-link :to="{name:'Product',params:{key:'p',p_id:detail.p_cate.id,p_name:detail.p_cate.name,c_id:detail.cate.id,c_name:detail.cate.name}}">{{detail.p_cate.name}}</router-link>
			<em>></em>
			<router-link :to="{name:'Product',params:{key:'c',p_id:detail.p_cate.id,p_name:detail.p_cate.name,c_id:detail.cate.id,c_name:detail.cate.name}}">{{detail.cate.name}}</router-link>
		</div>
		<div class="content-cls detail-box">
			<div class="detail-top">
				<div class="left-box">
					<el-image class="big-img" :src="bigImg" fit="fill"></el-image>
					<div class="partner-box" v-if="detail.detail_imgs.length > 5">
						<div class="left-arrow" @click="leftArrow">
							<img src="../assets/arrow.png" alt="">
						</div>
						<div class="hidden-box">
							<div class="small-img-list move"  :style="[partnerStyle]">
								<el-image class="img-cls" :class="{'active-cls': img == bigImg}" :src="img" v-for="(img, index) in detail.detail_imgs"
								 :key="index" fit="fill" @mouseenter="showImg(img)"></el-image>
							</div>
						</div>
						<div class="right-arrow" @click="rightArrow">
							<img src="../assets/arrow.png" alt="">
						</div>
					</div>
					<div class="small-img-list marTop" v-else>
						<el-image class="img-cls" :class="{'active-cls': img == bigImg}" :src="img" v-for="(img, index) in detail.detail_imgs"
						 :key="index" fit="fill" @mouseenter="showImg(img)"></el-image>
					</div>
				</div>
				<div class="right-box">
					<div class="title-cls">{{detail.title}}</div>
					<div class="price-cls">
						<span class="name-cls">价格</span>
						<span class="red-cls">￥{{detail.min_price}} - {{detail.max_price}}</span>
					</div>
					<div class="color-box">
						<span class="name-cls">定制设计</span>
						<div class="color-list">
							<!-- <div class="color-item" :class="{'active-cls': colorIdx == index}" v-for="(item, index) in detail.color" :key="item"
							 @click="chooseColor(index)">{{item}}</div> -->
							<div class="color-item" @click="goWeb('http://user.entsku.com')">立即体验</div>
						</div>
					</div>
					<div class="more-text" v-if="detail.xuantian" v-html="detail.xuantian"></div>
					<!-- <div class="btn-cls">立即下单</div>
					<div class="tag-box">
						<i class="el-icon-warning"></i>
						<span>下单请前往微信查看最新报价</span>
					</div> -->
				</div>
			</div>
			<div class="detail-bottom">
				<div class="detail-bot-box recommend">
					<div class="title-cls"><span>相关推荐</span></div>
					<ul>
						<li v-for="item in detail.relate_product" :key="item.id" @click="goDetail(item.id)"><img :src="item.img_id"></li>
					</ul>
				</div>
				<div class="detail-bot-box desc">
					<div class="title-cls"><span>宝贝详情</span></div>
					<div class="rich-html" v-html="detail.desc"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				currentId: '', // 当前产品的ID
				detail: null, // 产品详情
				bigImg: null, // 大图显示
				colorIdx: null, // 当前颜色选中值
				current: 0
			}
		},
		computed: {
			partnerStyle() {
				// 左右滑动距离
				let style = {};
				let num = this.current * 100; // 当前位移
				style.transform = `translateX(-${num}px)`;
				return style;
			}
		},
		created() {
			this.currentId = this.$route.params.id;
			this.getData();
		},
		methods: {
			// 获取数据
			getData() {
				this.$api.getProductDetail({
					id: this.currentId
				}).then(res => {
					if (res.code == '0000') {
						this.detail = res.data;
						this.bigImg = res.data.img_id;
					}
				})
			},
			// 鼠标经过显示大图
			showImg(img) {
				this.bigImg = img;
			},
			// 选中颜色
			chooseColor(idx) {
				this.colorIdx = idx;
			},
			// 上一个
			leftArrow() {
				if (this.current > 0) {
					this.current--;
				}
			},
			// 下一个
			rightArrow() {
				if (this.detail.detail_imgs.length > (this.current + 4)) {
					this.current++;
				}
			},
			goWeb(url) {
				if (url) {
					if (url.indexOf('http') == -1) {
						url = 'https://' + url
					}
					window.open(url, '_blank');
				}
			},
			// 跳转到详情
			goDetail(id) {
				this.$router.push({
					path: `/productDetail/${id}`
				})
			},
		},
		watch:{
			'$route'(to, from) {//监听到路由（参数）改变
				this.currentId = this.$route.params.id;
				this.getData();
			}
		},
	}
</script>

<style scoped lang="scss">
	.detail-img-bg{
		margin-bottom: 20px;
	}
	.detail-box {
		padding: 20px 0;
		.detail-top {
			display: flex;
			padding: 20px;
			width: 1260px;
			background: #FFFFFF;
			border: 1px solid #E6E6E6;
			box-sizing: border-box;

			.left-box,
			.right-box {
				display: inline-block;
				vertical-align: top;
			}

			.left-box {
				font-size: 0;
			}

			.big-img {
				width: 535px;
				height: 500px;
			}
			
			.partner-box {
				margin-top: 20px;
				width: 532px;
				display: flex;
				align-items: center;
			}
			
			.hidden-box {
				overflow: hidden;
				white-space: nowrap;
			}

			.small-img-list {
				text-align: center;

				.img-cls {
					height: 68px;
					width: 68px;
					margin-left: 25px;
					display: inline-block;
					cursor: pointer;
					border: 2px solid #fff;
				}

				.img-cls:first-child {
					margin-left: 0;
				}
				
				.img-cls:hover{
					transform: scale(1);
				}

				// .img-cls:hover {
				// 	border: 2px solid #ED4736;
				// }

				.active-cls {
					border: 2px solid #ED4736;
				}

			}

			.right-box {
				margin-left: 30px;
				text-align: left;
				flex: 1;

				.title-cls {
					font-size: 18px;
					color: #333;
					line-height: 26px;
				}

				.name-cls {
					width: 50px;
					display: inline-block;
				}

				.price-cls {
					background: #FEF1EF;
					padding: 30px 20px;
					margin: 20px 0;

					.red-cls {
						font-size: 24px;
						color: #ED4736;
						vertical-align: middle;
						font-weight: bold;
					}
				}

				.color-box {
					padding: 0 20px 10px;
					border-bottom: 1px dashed #ccc;

					.name-cls {
						vertical-align: top;
						padding-top: 12px;
					}

					.color-list {
						display: inline-block;
						margin-left: 32px;
						width: 460px;
					}

					.color-item {
						width: 80px;
						height: 40px;
						line-height: 40px;
						text-align: center;
						background: #FFFFFF;
						border: 2px solid #CCCCCC;
						border-radius: 4px;
						display: inline-block;
						margin-right: 10px;
						margin-bottom: 10px;
						cursor: pointer;
					}

					.color-item:nth-child(5n) {
						margin-right: 0;
					}

					.color-item.active-cls {
						border: 2px solid #ED4736;
					}
				}

				.btn-cls {
					width: 180px;
					height: 54px;
					background: #ED4736;
					border-radius: 2px;
					line-height: 54px;
					text-align: center;
					margin: 20px;
					color: #fff;
					font-size: 18px;
				}

				.tag-box {
					color: #999;
					margin-left: 20px;

					.el-icon-warning {
						margin-right: 6px;
					}
				}

				.more-text {
					padding: 10px;
					line-height: 1.4rem;
				}
			}

			.left-arrow,
			.right-arrow {
				height: 70px;
				width: 68px;
				line-height: 100px;
				cursor: pointer;
			}

			.right-arrow img {
				transform: rotate(180deg);
			}
		}

		.detail-bottom {
			margin-top: 20px;
			display: flex;
			.detail-bot-box{
				border: 1px solid #E6E6E6;
			}
			.title-cls {
				position: relative;
				text-align: left;
				height: 45px;
				line-height: 45px;
				font-size: 14px;
				border-bottom: 1px solid #E6E6E6;
				background: #FAFAFA;
				>span{
					position: absolute;
					padding: 0 20px;
					top: -1px;
					left: 0;
					background: #fff;
					border-top: 2px solid #22529A;
					border-right:1px solid #E6E6E6;
				}
			}
			.recommend{
				width: 340px;
				margin-right: 20px;
				>ul{
					padding: 20px;
					display: flex;
					flex-wrap: wrap;
					>li{
						margin-right: 20px;
						margin-bottom: 20px;
						width: 140px;
						height: 140px;
						box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.1000);
						cursor: pointer;
						&:nth-child(2n){
							margin-right: 0;
						}
						&:hover{
							box-shadow: 0px 0px 6px 0px rgba(64, 158, 255, 0.5);
						}
						>img{
							display: block;
							width: 100%;
						}
					}
				}
			}
			.desc{
				flex: 1;
			}
			.rich-html {
				padding: 10px;
			}
		}
	}
	.marTop{
		margin-top: 20px;
	}
	
	.move{
	    transition: all .3s; //设置过渡动画
	}
</style>
