<template>
	<div v-if="detail">
		<img class="img-bg" :src="detail.banner_imgs" alt="">
		<div class="content-cls detail-box">
			<!-- 轮播图 -->
			<van-swipe class="product-swiper" :autoplay="3000" width="100%" indicator-color="white">
				<van-swipe-item v-for="(item, idx) in detail.detail_imgs" :key="idx">
					<van-image class="img-cls" :src="item" fit="contain" lazy-load @click="showPreview = true; previewIdx = idx" />
				</van-swipe-item>
			</van-swipe>
			
			<div class="detail-content">
				<div class="price-cls">
					<span class="name-cls">价格：</span>
					<span class="red-cls">￥{{detail.min_price}} - {{detail.max_price}}</span>
				</div>
				<div class="title-cls">{{detail.title}}</div>
				<div class="color-box">
					<span class="name-cls">属性：</span>
					<div class="color-list">
						<div class="color-item" :class="{'active-cls': colorIdx == index}" v-for="(item, index) in detail.color" :key="item"
						 @click="chooseColor(index)">{{item}}</div>
					</div>
				</div>
				<div class="more-text" v-if="detail.xuantian" v-html="detail.xuantian"></div>
				<!-- <div class="btn-cls">立即下单</div>
				<div class="tag-box">
					<i class="el-icon-warning"></i>
					<span>下单请前往微信查看最新报价</span>
				</div> -->
			</div>
			<div class="detail-bottom">
				<div class="title-cls">宝贝详情</div>
				<div class="rich-html" v-html="detail.desc"></div>
			</div>
		</div>
		<!-- 预览图片 -->
		<van-image-preview v-model="showPreview" :images="detail.detail_imgs" :start-position="previewIdx" @change="onChange">
		</van-image-preview>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				currentId: '', // 当前产品的ID
				detail: null, // 产品详情
				colorIdx: null, // 当前颜色选中值
				current: 0,
				showPreview: false, // 显示预览图
				previewIdx: 0
			}
		},
		created() {
			this.currentId = this.$route.params.id;
			this.getData();
		},
		methods: {
			// 获取数据
			getData() {
				this.$api.getProductDetail({
					id: this.currentId
				}).then(res => {
					if (res.code == '0000') {
						this.detail = res.data;
					}
				})
			},
			// 选中颜色
			chooseColor(idx) {
				this.colorIdx = idx;
			}
		}
	}
</script>

<style scoped lang="scss">
	.detail-box {
		padding: 1rem 0;
		
		.product-swiper {
			font-size: 0;
			height: 20rem;
			border-top: 1px solid #e5e5e5;
		
			.img-cls {
				height: 100%;
				width: 100%;
			}
		}
		
		.detail-content{
			margin-top: 0.5rem;
			text-align: left;
			
			.title-cls{
				font-size: 1.2rem;
				font-weight: bold;
				padding: 0 1rem;
			}
		}
		
		.price-cls{
			background: #FEF1EF;
			margin: 0.5rem 0;
			font-size: 1rem;
			padding: 1rem;
			
			.red-cls {
				color: #ED4736;
				font-size: 1.2rem;
				vertical-align: -2px;
			}
		}
		
		.color-box {
			padding: 1rem;
			border-bottom: 1px dashed #ccc;
			display: flex;
			align-items: flex-start;
		
			.name-cls {
				font-size: 0.8rem;
				margin-right: 0.5rem;
				padding-top: 0.6rem;
			}
		
			.color-list {
				flex: 1;
			}
		
			.color-item {
				width: 4rem;
				height: 2rem;
				line-height: 2rem;
				text-align: center;
				background: #FFFFFF;
				border: 2px solid #CCCCCC;
				border-radius: 0.3rem;
				display: inline-block;
				margin-right: 0.6rem;
				margin-bottom: 0.6rem;
				cursor: pointer;
			}
		
			.color-item:nth-child(5n) {
				margin-right: 0;
			}
		
			.color-item.active-cls {
				border: 2px solid #ED4736;
			}
		}
		
		.more-text{
			padding: 1rem 1rem 0;
			line-height: 1.4rem;
		}
		
		.detail-bottom{
			margin-top: 1rem;
			border-top: 1px solid #E6E6E6;
			
			/deep/ img{
				width: 100%;
			}
			
			.title-cls {
				text-align: left;
				height: 2rem;
				line-height: 2rem;
				font-size: 0.8rem;
				border-bottom: 1px solid #E6E6E6;
				background: #FAFAFA;
				padding-left: 1rem;
			}
		}
	}
</style>
